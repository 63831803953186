<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      app
      :color="color === 'transparent' ? 'primary' : color"
      temporary
    >
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title"
              ><v-img
                src="@/assets/flety_600.png"
                alt="Logo"
                class="mr-16"
                max-width="130"
            /></v-list-item-title>
            <!-- <v-list-item-subtitle>Ridery</v-list-item-subtitle> -->
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider />
      <v-list dense nav>
        <v-list-item link>
          <v-list-item-content>
            <v-list-item>
              <v-list-item-content
                class="color-white-text text-xl font-weight-bold"
                @click="$router.push({ name: 'home' })"
              >
                Volver
              </v-list-item-content>
            </v-list-item>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item link>
          <v-list-item-content>
            <v-list-item >
              <v-list-item-content
                class="color-white-text text-xl font-weight-bold"
              >
                Empresas transporte
              </v-list-item-content>
            </v-list-item>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link>
          <v-list-item-content>
            <v-list-item >
              <v-list-item-content
                class="color-white-text text-xl font-weight-bold"
              >
                Generadores de carga
              </v-list-item-content>
            </v-list-item>
          </v-list-item-content>
        </v-list-item> -->
        <v-list-item link v-if="false">
          <v-list-item-content>
            <v-list-item>
              <v-list-item-content
                class="color-white-text text-xl font-weight-bold"
              >
                ES
              </v-list-item-content>
            </v-list-item>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar height="72" :color="color" :flat="flat">
      <v-container
        class="d-flex flex-row justify-lg-space-between align-center"
      >
        <v-toolbar-title
          ><v-img src="@/assets/flety_600.png" max-width="130"></v-img
        ></v-toolbar-title>
        <v-spacer></v-spacer>
        <span v-if="$vuetify.breakpoint.mdAndUp">
          <v-btn
            id="btn-home"
            text
            color="white"
            @click="$router.push({ name: 'home' })"
            class="font-weight-bold"
          >
            Volver
          </v-btn>
          <!-- <v-btn
            class="ml-2 font-weight-bold"
            text
            color="white"
            @click="$vuetify.goTo('#drivers')"
          >
            Empresas transporte
          </v-btn>
          <v-btn
            class="ml-2 font-weight-bold"
            text
            color="white"
            @click="$vuetify.goTo('#benefits')"
          >
            Generadores de carga
          </v-btn> -->
          <template v-if="false">
            <v-divider class="ml-2" vertical></v-divider>
            <v-btn class="ml-2 font-weight-bold" text color="white"> ES </v-btn>
          </template>
        </span>
        <span v-else>
          <v-app-bar-nav-icon color="white" @click="drawer = !drawer">
            <template #default>
              <span v-if="!drawer" class="">
                <v-icon x-large> mdi-menu</v-icon>
              </span>
              <span v-else> <v-icon x-large> mdi-close</v-icon> </span>
            </template>
          </v-app-bar-nav-icon>
        </span>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  props: {
    color: { type: String, default: "primary" },
    flat: { type: Boolean, default: false },
  },
  data() {
    return {
      drawer: false,
    };
  },
  computed: {
    computedMdAndUp() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
  },
  watch: {
    computedMdAndUp(newVal) {
      if (newVal) {
        this.drawer = false;
      }
    },
  },
};
</script>

<style></style>
