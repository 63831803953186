import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#250e62",
        accent: "#EF4370",
        secondary: "#00BFB3",
        success: "#00BFB3",
        info: "#244074",
        warning: "#FFB400",
        error: "#C6007E",
      },
      dark: {
        primary: "#3BD4AE",
        accent: "#244074",
        secondary: "#3BD4AE",
        success: "#3BD4AE",
        info: "#244074",
        warning: "#FFB400",
        error: "#EF4370",
      },
    },
  },
});
