import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import "@/assets/styles/global.css";
import "@/scss/styles.scss";
import Carousel3d from "vue-carousel-3d";
import store from "./store";
import router from "./router";
import VueGtag from "vue-gtag";

import { InitializeGoogleMapApi } from "./plugins/google";

InitializeGoogleMapApi("AIzaSyCVK_8wydWrkeeO4edLquH9z9V4bMOrGWQ").then(() => {
  Vue.config.productionTip = false;
  Vue.use(Carousel3d);
  Vue.use(VueGtag, {
    config: {
      id: "GTM-KSGFFL5",
    },
  });
  new Vue({
    vuetify,
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
});
