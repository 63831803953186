import Vue from 'vue'
import Vuex from 'vuex'
// import { createStore } from 'vuex'
import axios from "axios";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    allUsers: [],
  },
  mutations: {
    setAllUsers (state, payload) {
      console.log("PAYLOAD API DATA IN MUTATION ==>", payload);
      console.log("STATE BEFORE ADDING DATA ==>", state.allUsers);
      state.allUsers = payload;
      console.log("STATE AFTER ADDING DATA ==>", state.allUsers);
    }
  },
  actions: {
    getDropDownData ({commit}) {
      console.log("getDropDownData in ACtion");
      axios.get('https://flety.io/w_typelist')
      .then((res) => {
        console.log("response ===> ", res.data);

        commit("setAllUsers", res.data);
      })
      .catch(err => {
        console.log(err);
      });
    },
  },
  modules: {

  },
  getters: {
    allUsers (state) {
      console.log("Getter ==>");
      return state.allUsers;
    }
  }
})
