<template>
  <v-app>
    <AppBar
      :color="color"
      :flat="flat"
      :fab="fab"
      v-if="$route.name === 'home' || $route.name === 'Error'"
    />
    <AppBarOther v-else />
    <v-main class="pt-0">
      <transition name="scroll-x-transition" mode="out-in" appear>
        <router-view />
      </transition>
    </v-main>
    <FooterComp />
    <span
      v-if="$route.name === 'home' || $route.name === 'Error'"
      id="fab_whatsapp"
    >
      <v-btn
        v-on:click="trackBtnWhatsapp()"
        id="btn-contacto-whatsapp"
        fab
        v-show="fab"
        v-scroll="onScroll"
        dark
        fixed
        bottom
        right
        color="secondary"
        x-large
        class="mb-md-10 mr-md-10 mb-5 mr-5"
        href="https://api.whatsapp.com/send?phone=584126435389&amp;text=%C2%A1Hola%20Flety!%20Necesito%20informaci%C3%B3n"
        target="_blank"
      >
        <!-- @click="toTop" -->
        <v-icon x-large>mdi-whatsapp</v-icon>
      </v-btn>
    </span>
  </v-app>
</template>

<script>
import FooterComp from "@/components/FooterComp.vue";
import AppBar from "@/components/AppBar.vue";
import AppBarOther from "@/components/AppBarOther.vue";

export default {
  name: "App",

  components: {
    FooterComp,
    AppBar,
    AppBarOther,
  },

  data: () => ({
    color: "",
    flat: null,
    fab: null,
  }),

  created() {
    const top = window.pageYOffset || 0;
    if (top <= 20) {
      this.color = "transparent";
      this.flat = true;
    } else {
      this.color = "secondary";
      this.flat = true;
    }
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 375;
      // ajustar color segun seccion
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    track() {
      this.$gtag.pageview({ page_path: "/" });
      this.$gtag.pageview("/");
      this.$gtag.pageview(this.$route);
    },
    trackBtnWhatsapp() {
      this.$gtag.event("btn_contacto_whatsapp", {
        event_category: "btn",
        event_label: "btn_contacto_whatsapp",
        value: "btn_contacto_whatsapp",
      });
    },
  },

  watch: {
    fab(value) {
      if (value) {
        this.color = "primary";
        this.flat = false;
      } else {
        this.color = "transparent";
        this.flat = true;
      }
    },
  },
};
</script>
