<template lang="">
  <v-footer color="primary" padless elevation="24">
    <v-container class="pa-0 d-flex flex-column">
      <v-row>
        <v-col cols="12" class="d-flex flex-row justify-center">
          <v-img
            src="@/assets/flety_600.png"
            alt="Logo Flety"
            max-width="200"
            class="mt-4"
          />
        </v-col>
      </v-row>
      <v-row class="d-flex flex-row justify-center align-start">
        <v-col
          cols="12"
          md="3"
          class="d-flex flex-column justify-center align-center"
        >
          <h3 class="secondary--text">Contacto</h3>
          <a
            style="color: white"
            href="https://api.whatsapp.com/send?phone=584126435389&amp;text=%C2%A1Hola%20Flety!%20Necesito%20informaci%C3%B3n"
            target="_blank"
            class="text-decoration-none"
            id="btn-to-whatsapp"
            v-on:click="trackBtnWhatsapp()"
          >
            <v-icon color="white"> mdi-whatsapp </v-icon>
            <span class="ml-1">+58 412-643-53-89 </span>
          </a>
          <a
            id="btn-to-email"
            style="color: white"
            href="mailto:hola@flety.io"
            target="_blank"
            class="text-decoration-none mt-2"
          >
            <v-icon color="white"> mdi-email-outline </v-icon>
            <span class="ml-1"> hola@flety.io </span>
          </a>
        </v-col>
        <v-col
          cols="12"
          md="3"
          class="d-flex flex-column justify-center align-center"
        >
          <h3 class="secondary--text text-center">
            Síguenos en redes sociales
          </h3>
          <a
            id="btn-to-instagram"
            style="color: white"
            href="https://www.instagram.com/fletyapp/"
            target="_blank"
            class="text-decoration-none"
          >
            <v-icon color="white"> mdi-instagram </v-icon>
            <span class="ml-1"> @fletyapp </span>
          </a>
          <a
            id="btn-to-instagram"
            style="color: white"
            href="https://ve.linkedin.com/company/flety-venezuela"
            target="_blank"
            class="text-decoration-none mt-2"
          >
            <v-icon color="white"> mdi-linkedin </v-icon>
            <span class="ml-1"> Flety Venezuela </span>
          </a>
          <a
            id="btn-to-youtube"
            style="color: white"
            href="https://www.youtube.com/@fletyappVzla"
            target="_blank"
            class="text-decoration-none mt-2"
          >
            <v-icon color="white"> mdi-youtube </v-icon>
            <span class="ml-1"> @fletyappVzla </span>
          </a>
          <a
            id="btn-to-twitter"
            style="color: white"
            href="https://twitter.com/fletyapp"
            target="_blank"
            class="text-decoration-none mt-2"
          >
            <v-icon color="white"> mdi-twitter </v-icon>
            <span class="ml-1"> @fletyapp </span>
          </a>
          <a
            id="btn-to-facebook"
            style="color: white"
            href="https://www.facebook.com/fletyapp"
            target="_blank"
            class="text-decoration-none mt-2"
          >
            <v-icon color="white"> mdi-facebook </v-icon>
            <span class="ml-1"> @fletyapp </span>
          </a>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col class="primary py-4 text-center white--text" cols="12">
          <span class="font-weight-bold">Flety</span> © Todos los derechos
          reservados. <br v-if="!$vuetify.breakpoint.mdAndUp" />
          Hecho en Venezuela con
          <span>
            <v-icon class="ms-1" color="secondary">
              mdi-heart-outline
            </v-icon></span
          >
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>
<script>
export default {
  methods: {
    trackBtnWhatsapp() {
      this.$gtag.event("btn_contacto_whatsapp", {
        event_category: "btn",
        event_label: "btn_contacto_whatsapp",
        value: "btn_contacto_whatsapp",
      });
    },
  },
};
</script>
<style lang="scss"></style>
